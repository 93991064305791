import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputField from 'common/input/InputField';
import useScreenSize from 'hooks/useScreenSize';
import DatePicker from 'common/input/DatePicker';
import { addYears } from 'date-fns';
import SearchSelect from 'common/input/SearchSelect';
import RichTextfield from 'common/input/richTextField/RichTextfield';

import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';

import { getOrderAssociations } from 'api/listApis';
import { isEmpty } from 'lodash';

const DispatchOrderForm = ({
  formData,
  isEdit = false,
  handleEditApply = () => {},
}) => {
  const { isMobile } = useScreenSize();
  const [woList, setWoList] = useState([]);
  const [soList, setSoList] = useState([]);

  const commonInputProps = {
    formData: formData,
    isEditable: isEdit,
    onEditApply: handleEditApply,
  };

  const {
    userList,
    projects,
  } = useSelector(state => state.lists)
  const {
    userFirstName,
    userLastName,
  } = useSelector(state => state.userDetails)

  useEffect(() => {
    if (!formData) return;
    formData?.setValue('createdBy', `${userFirstName} ${userLastName}`);
    formData?.setValue('created', new Date().toISOString());
  }, [userFirstName, userLastName]);

  useEffect(() => {
    if (!formData?.getValues('projectId')) return;

    getOrderAssociations(formData?.getValues('projectId'))
      .then(res => {
        setWoList(res?.workOrders);
        setSoList(res?.salesOrders);
      });
  }, [formData?.getValues('projectId')]);

  return (
    <>
      <Grid
        px={!isMobile && 2}
        py={2}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid sm={4} xs={12} item>
          <InputField
            required
            {...commonInputProps}
            name='dispatchNo'
            label='Dispatch Number'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='dispatchType'
            required
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='dispatchMethod'
            required
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <SearchSelect
            {...commonInputProps}
            name='dispatchUser'
            required
            returnLabel
            searchSelectData={userList?.list || []}
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='consignmentNo'
            label='Consignment Number'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='containerNo'
            label='Container Number'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='sealNo'
            label='Seal Number'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='totalWeight'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='totalCubicMetres'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='carrier'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='dispatchStatus'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <DatePicker
            {...commonInputProps}
            name='dispatchedDate'
            required
            maxDate={new Date()}
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <DatePicker
            {...commonInputProps}
            name='pickUpDate'
            required
            maxDate={addYears(new Date(), 10)}
            minDate={new Date()}
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='weight'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='height'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='length'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='width'
          />
        </Grid>

        {/* <Grid sm={4} xs={12} item>
          <SearchSelect
            {...commonInputProps}
            name='projectId'
            label='Project'
            searchSelectData={projects}
          />
        </Grid> */}

        <CustomerProjectSelectors
          formData={formData}
          sm={4}
          md={4}
          xs={12}
        />

        <Grid sm={4} xs={12} item>
          <SearchSelect
            {...commonInputProps}
            name='salesOrderId'
            label='Sales Order Number'
            searchSelectData={soList}
            disabled={isEmpty(soList)}
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <SearchSelect
            {...commonInputProps}
            name='workOrderId'
            label='Work Order Number'
            searchSelectData={woList}
            disabled={isEmpty(woList)}
          />
        </Grid>

        <Grid sm={12} xs={12} item>
          <Typography
            component='div'
            variant='p3'
            fontWeight='500'
            mb={1}
          >
            Comment
          </Typography>
          <RichTextfield
            formData={formData}
            name='comments'
            placeholder="Comments..."
            disableImage
          />
        </Grid>

      </Grid>
    </>
  );
}

export default DispatchOrderForm;
