import React from 'react'

const CheckedRoundIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48" fill="none">
            <g clipPath="url(#clip0_741_106)">
                <path d="M23.9999 0C10.7664 0 0 10.7662 0 23.9999C0 37.2336 10.7664 48 23.9999 48C37.2333 48 47.9997 37.2336 47.9997 23.9999C47.9997 10.7662 37.2336 0 23.9999 0ZM37.781 19.9461L22.7031 35.024C22.062 35.6651 21.2098 36.018 20.3032 36.018C19.3966 36.018 18.5444 35.6651 17.9033 35.024L10.2187 27.3394C9.57761 26.6983 9.2245 25.8461 9.2245 24.9395C9.2245 24.0327 9.57761 23.1805 10.2187 22.5393C10.8596 21.8982 11.7118 21.5451 12.6186 21.5451C13.5252 21.5451 14.3777 21.8982 15.0185 22.5396L20.303 27.8238L32.9807 15.146C33.6218 14.5049 34.474 14.152 35.3806 14.152C36.2872 14.152 37.1394 14.5049 37.7805 15.146C39.1043 16.4698 39.1043 18.6228 37.781 19.9461Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_741_106">
                    <rect width="100%" height="100%" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CheckedRoundIcon