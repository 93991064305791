import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Controller } from 'react-hook-form'

import { FormControlLabel, IconButton, Box, Switch, Checkbox } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const SwitchToggle = ({
  formData,
  name = '',
  labelPlacement = 'start',
  size = 'small',
  onEditApply = () => {},
  isEditable = false,
  switchProps,
  isCheckBox = false,
  onChange = () => {},
  disableStar,
  ...rest
}) => {
  const [editedValue, setEditedValue] = useState(false);

  const watch = useWatch;
  const watchedData = formData?.control ? watch({control: formData?.control}) : [];

  useEffect(() => {
    formData?.setValue(name, !!watchedData[name]);
  }, []);

  useEffect(() => {
    setEditedValue(!!watchedData[name]);
  }, [watchedData]);

  const onClickApply = () => {
    formData?.setValue(name, editedValue);
    onEditApply(watchedData, editedValue);
  }

  const onEditCancel = () => {
    setEditedValue(!!watchedData[name]);
  }

  if(!formData) return (
    <FormControlLabel
      control={
        <Box
          component={isCheckBox ? Checkbox : Switch}
          {...switchProps}
          size={size}
          onChange={(e, val) => onChange(name, val)}
        />
      }
      labelPlacement={labelPlacement}
      {...rest}
    />
  );

  return (
    <Box display='flex' alignItems='center'>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={formData?.control}
            render={({ field }) => (
              <Box
                {...field}
                component={isCheckBox ? Checkbox : Switch}
                value={!!field?.value}
                onChange={(e) => {
                  isEditable
                    ? setEditedValue(e.target.checked)
                    : field?.onChange(e.target.checked);
                  onChange(name, e.target.checked);
                } }
                size={size}
                checked={isEditable ? editedValue : !!field?.value}
              />
            )}
          />
        }
        labelPlacement={labelPlacement}
        {...rest}
      />

      {isEditable && editedValue !== !!formData?.getValues(name) &&
        <>
          <IconButton
            size='small'
            onClick={onClickApply}
          >
            <CheckCircleRoundedIcon color='primary' fontSize='small'/>
          </IconButton>
          <IconButton
            size='small'
            onClick={onEditCancel}
          >
            <CancelOutlinedIcon fontSize='small'/>
          </IconButton>
        </>
      }
    </Box>
  );
}

export default SwitchToggle;
