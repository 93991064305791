import React from 'react';

import Grid from '@mui/material/Grid';
import InputField from 'common/input/InputField';
import SearchSelect from 'common/input/SearchSelect';

import { lettersOnly } from 'utils/textFormatUtils';
import useScreenSize from 'hooks/useScreenSize';
import { useSelector } from 'react-redux';

import { countryList, StateField } from 'enum/addressEnum';

import RichNoteField from 'common/input/RichNoteField';
import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';

const VendorsForm = ({
  formData,
  isEdit = false,
  handleEditApply = () => {},
}) => {
  const { isMobile } = useScreenSize();

  const {
    accountList,
  } = useSelector(state => state.lists)

  const commonInputProps = {
    formData: formData,
    isEditable: isEdit,
    onEditApply: handleEditApply,
  };

  return (
    <>
      {/* <Box sx={{
        backgroundColor: theme => theme.palette.common.backgroundGrey,
        px: 2,
        py: 1,
      }}>
        <Typography>Vendors Information</Typography>
      </Box> */}

      <Grid
        px={!isMobile && 4}
        py={2}
        container
        rowSpacing={2}
        columnSpacing={4}
      >
        <Grid sm={3} xs={12} item>
          <InputField
            format={lettersOnly}
            required
            {...commonInputProps}
            name='vendorName'
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='productOrServiceOffered'
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='contactNumber' label='Contact'
            // format={numberOnly}
            required
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='email'
            type='email'
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='suburb'
          />
        </Grid>
        <Grid sm={3} xs={12} item>
            <SearchSelect
            {...commonInputProps}
            name='country'
            searchSelectData={countryList}
          />
        </Grid>
        <Grid sm={3} xs={12} item>
          <StateField
            selectedCountry={formData?.watch('country')}
            inputProps={commonInputProps}
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='postcode'
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='abn'
            // format={numberOnly}
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='bankName'
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='bankAccountNumber'
            // format={numberOnly}
          />
        </Grid>

        <Grid sm={3} xs={12} item>
          <InputField
            {...commonInputProps}
            name='paymentTerms'
          />
        </Grid>

        <CustomerProjectSelectors
          formData={formData}
          sm={3}
          md={3}
          xs={12}
        />

        <Grid xs={12} item>
          <RichNoteField
            formData={formData}
            name='notes'
          />
        </Grid>

        {/* <Grid sm={4} xs={12} item>
          <DatePicker
            {...commonInputProps}
            name='lastPurchaseDate'
          />
        </Grid> */}

        {/* <Grid sm={4} xs={12} item>
          <DatePicker
            {...commonInputProps}
            name='lastPaymentDate'
          />
        </Grid> */}

        {/* <Grid sm={4} xs={12} item>
          <SwitchToggle
            {...commonInputProps}
            name='isPreferredVendor'
            label={<Typography variant='p2'>Is Preferred Vendor&nbsp;</Typography>}
          />
        </Grid> */}

        {/* <Grid sm={4} xs={12} item>
          <SwitchToggle
            {...commonInputProps}
            name='isActive'
            label={<Typography variant='p2'>Is Active&nbsp;</Typography>}
          />
        </Grid> */}
      </Grid>
    </>
  );
}

export default VendorsForm;
