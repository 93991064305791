import React from 'react'

const CheckedIcons = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  fill="none">
            <path d="M17.0637 0.333099H6.94874C2.99374 0.333099 0.33374 3.10977 0.33374 7.23977V16.7714C0.33374 20.8898 2.99374 23.6664 6.94874 23.6664H17.0637C21.0187 23.6664 23.6671 20.8898 23.6671 16.7714V7.23977C23.6671 3.10977 21.0187 0.333099 17.0637 0.333099Z" fill={color} />
            <path d="M10.6156 15.7892C10.3543 15.7892 10.0929 15.69 9.89343 15.4905L7.12493 12.722C6.72593 12.323 6.72593 11.6767 7.12493 11.2789C7.52393 10.8799 8.1691 10.8787 8.5681 11.2777L10.6156 13.3252L15.4316 8.50921C15.8306 8.11021 16.4758 8.11021 16.8748 8.50921C17.2738 8.90821 17.2738 9.55454 16.8748 9.95354L11.3378 15.4905C11.1383 15.69 10.8769 15.7892 10.6156 15.7892Z" fill="white" />
        </svg>
    )
}

export default CheckedIcons