import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetails } from "api/masterApi";

export const fetchUserDetails = createAsyncThunk(
  "actions/fetchUserDetails",
  async () => await getUserDetails()
);

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => action.payload);
  },
});

export default userDetailsSlice.reducer;
