import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Selector from 'common/input/Selector';
import InputField from 'common/input/InputField';

import { numberOnly } from 'utils/textFormatUtils';
import useScreenSize from 'hooks/useScreenSize';

import RichNoteField from 'common/input/RichNoteField';
import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';

const PurchaseOrderForm = ({
  formData,
  isEdit = false,
  handleEditApply = () => {},
}) => {
  const { isMobile } = useScreenSize();

  const commonInputProps = {
    formData: formData,
    isEditable: isEdit,
    onEditApply: handleEditApply,
  };

  return (
    <>
      <Box sx={{
        backgroundColor: theme => theme.palette.common.backgroundGrey,
        px: 2,
        py: 1,
      }}>
        <Typography>Sales Order Information</Typography>
      </Box>

      <Grid
        px={!isMobile && 2}
        py={2}
        container
        rowSpacing={2}
        columnSpacing={4}
      >
        {/* <Grid sm={6} xs={12} item>
          <InputField
            format={numberOnly}
            required
            {...commonInputProps}
            name='purchaseOrderNo'
          />
        </Grid> */}

        {/* <Grid sm={6} xs={12} item>
          <DatePicker
            {...commonInputProps}
            name='dateApproved'
            required
            maxDate={addYears(new Date(), -18)}
          />
        </Grid> */}

        <CustomerProjectSelectors
          formData={formData}
          sm={4}
          md={4}
          xs={12}
        />

        <Grid sm={4} xs={12} item>
          <InputField
            format={numberOnly}
            {...commonInputProps}
            name='poTotal'
          />
        </Grid>
{/*
        <Grid sm={6} xs={12} item>
          <SwitchToggle
            {...commonInputProps}
            name='isSelected'
            label={<Typography>Is Selected</Typography>}
          />
        </Grid>

        <Grid sm={6} xs={12} item>
          <SwitchToggle
            {...commonInputProps}
            name='isExported'
            label={<Typography>Is Exported</Typography>}
          />
        </Grid> */}

        {/* <Grid sm={6} xs={12} item>
          <SwitchToggle
            {...commonInputProps}
            name='isInactive'
            label={<Typography>Is Inactive</Typography>}
          />
        </Grid> */}

        <Grid sm={4} xs={12} item>
          <InputField
            format={numberOnly}
            {...commonInputProps}
            name='requestorID' label='Requestor'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <InputField
            format={numberOnly}
            {...commonInputProps}
            name='supplierID' label='Supplier'
          />
        </Grid>
{/*
        <Grid sm={6} xs={12} item>
          <InputField
            format={numberOnly}
            {...commonInputProps}
            name='purchaseID'
          />
        </Grid> */}

        {/* <Grid sm={6} xs={12} item>
          <DatePicker
            {...commonInputProps}
            name='lastUpdated'
            maxDate={addYears(new Date(), 18)}
          />
        </Grid> */}
{/*
        <Grid sm={6} xs={12} item>
          <InputField
            {...commonInputProps}
            name='lastUpdateUser'
          />
        </Grid> */}

        <Grid sm={4} xs={12} item>
          <InputField
            format={numberOnly}
            {...commonInputProps}
            name='costCentreID'
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <Selector
            {...commonInputProps}
            name='poType'
            selectorData={[
              {value: 'inProgress', label: 'In Progress'},
              {value: 'completed', label: 'Completed'},
              {value: 'failed', label: 'Failed'},
            ]}
          />
        </Grid>

        <Grid sm={4} xs={12} item>
          <Selector
            {...commonInputProps}
            name='poStatus'
            selectorData={[
              {value: 'inProgress', label: 'In Progress'},
              {value: 'completed', label: 'Completed'},
              {value: 'failed', label: 'Failed'},
            ]}
          />
        </Grid>



        {/* <Grid sm={12} xs={12} item>
          <InputField
            {...commonInputProps}
            name='approverComments'
          />
        </Grid> */}

        {/* <Grid sm={6} xs={12} item>
          <DatePicker
            {...commonInputProps}
            name='lastActionedDate'
            maxTime={addYears(new Date(), 5)}
          />
        </Grid> */}

        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='supplierInvoiceNo'
          />
        </Grid>

        {/* <Grid sm={6} xs={12} item>
          <InputField
            {...commonInputProps}
            name='purchaseOrder_Invoice'
            format={numberOnly}
          />
        </Grid> */}

        {/* <Grid sm={6} xs={12} item>
          <InputField
            {...commonInputProps}
            name='purchaseOrder_POAppover'
            format={numberOnly}
          />
        </Grid> */}

        {/* <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='purchaseOrder_Project' label='Project'
            format={numberOnly}
          />
        </Grid>
        <Grid sm={4} xs={12} item>
          <InputField
            {...commonInputProps}
            name='purchaseOrder_Project' label='Account'
            format={numberOnly}
          />
        </Grid> */}
        <Grid sm={12} xs={12} item>
          <RichNoteField
            formData={formData}
            name='requestorComments'
            label='Comment'
            placeholder='Detailed Comment...'
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PurchaseOrderForm;
