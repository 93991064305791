import { Box, IconButton, Typography } from "@mui/material";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DescriptionOutlined as DocumentIcon } from '@mui/icons-material';

import { base64Download } from "utils/fileHelperFunctions";
import { saveAs } from "file-saver";

const FileItem = ({
  file,
  fileName = '',
  fileType = '',
  onClickRemove = null,
  fileIsBase64 = false,
}) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          position: 'relative',
        }}
      >
        <IconButton
          color='secondary'
          variant='outlined'
          sx={{
            p: 2,
            border: theme => `1px solid ${theme.palette.secondary.main}`,
          }}
          onClick={() => fileIsBase64 ? base64Download(file, fileName, fileType) : saveAs(file, fileName)}
        >
          <DocumentIcon sx={{ fontSize: '40px' }} />
        </IconButton>

        {onClickRemove &&
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              top: -10,
              right: 12,
            }}
          >
            <IconButton
              size='small'
              color='error'
              onClick={onClickRemove}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>}

        <Typography
          variant='p3'
          textAlign='center'
          sx={{
            overflow: 'hidden',
            width: '100%',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {fileName}
        </Typography>
      </Box>
    );
  };

export default FileItem;