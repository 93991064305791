import { saveAs } from "file-saver";

// Function to convert Blob to string
export const blobToString = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result); // This will return the Blob as a string
    };
    reader.onerror = reject; // Handle any errors
    reader.readAsText(blob); // Read the blob as text
  });
};

export const stringToBlob = (str, type = 'text/plain') => {
  return new Blob([str], { type });
};

export const base64Download = (base64String, fileName, mimeType) => {
  const binaryString = window.atob(base64String);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: mimeType });
  saveAs(blob, fileName);
};

export const blobToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(',')[1]); // Resolve with the base64 string
    reader.onerror = (error) => reject(error); // Reject in case of an error
    reader.readAsDataURL(file); // Read the file
  });
};

export const base64ToImgSrc = (base64 = '') => {
  return `data:image/png;base64,${base64}`
}
