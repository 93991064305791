import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Selector from 'common/input/Selector';
import InputField from 'common/input/InputField';
import SwitchToggle from 'common/input/SwitchToggle';
import SearchSelect from 'common/input/SearchSelect';
import { numberOnly } from 'utils/textFormatUtils';
import useScreenSize from 'hooks/useScreenSize';
import { useSelector } from 'react-redux';

import RichNoteField from 'common/input/RichNoteField';

import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';

const LoadsForm = ({
  formData,
  isEdit = false,
  handleEditApply = () => {},
}) => {
  const { isMobile } = useScreenSize();

  const {
    projects,
    warehouses,
    workOrderList,
    allStatusList: { load },
  } = useSelector(state => state.lists);

  const [isAutoGenerated, setIsAutoGenerated] = useState(false);
  const [ssn, setSsn] = useState('');

  const commonInputProps = {
    formData: formData,
    isEditable: isEdit,
    onEditApply: handleEditApply,
  };

  const gridItemSize = {
    sm: 4,
    md: 3,
    xs: 6,
  };

  return (
    <>
      <Grid
        px={!isMobile && 2}
        py={2}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid {...gridItemSize} item>
          <SearchSelect
            required
            name="warehouseId"
            label="Warehouse"
            searchSelectData={warehouses}
            {...commonInputProps}
          />
        </Grid>

        {/* <Grid {...gridItemSize} item>
          <SearchSelect
            required
            name="projectId"
            label="Project"
            searchSelectData={projects}
            {...commonInputProps}
          />
        </Grid> */}

        <CustomerProjectSelectors
          {...gridItemSize}
          formData={formData}
        />

        <Grid {...gridItemSize} item>
          <SearchSelect
            required
            name="workOrderId"
            label="Work Order"
            searchSelectData={workOrderList}
            {...commonInputProps}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name="connote"
            label="connote"
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name="clientRef"
            label="clientRef"
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name="clientPO"
            label="clientPO"
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <SearchSelect
            {...commonInputProps}
            name="loadStatus"
            searchSelectData={load?.map(val => ({ id: val.statusId, value: val.status }))}
            returnLabel
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            required
            name="estimatedQty"
            label="Estimated Qty"
            format={numberOnly}
            {...commonInputProps}
          />
        </Grid>

        <Grid sm={12} md={12} xs={12} item>
          <RichNoteField
            formData={formData}
            name='comments'
            label='Comments'
            placeholder='Detailed comments...'
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <SwitchToggle
            name="isAutoGenerated"
            label="Is Auto Generated"
            checked={isAutoGenerated}
            onChange={() => setIsAutoGenerated(!isAutoGenerated)}
            {...commonInputProps}
          />
        </Grid>

        {isAutoGenerated && (
          <Grid {...gridItemSize} item>
            <InputField
              name="startingSSN"
              label="Starting SSN"
              placeholder="SSN Here.."
              {...commonInputProps}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LoadsForm;