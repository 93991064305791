import React, { useState } from 'react';

import { Box } from '@mui/material';

import TabsMenu from 'common/dataDisplay/Tabs/TabsMenu';

import TSResourceTab from './TSResourceTab';
import TSOrdersTab from './TSOrdersTab';

const TimeSheetForm = ({
  formData,
  isUpdate,
}) => {
  const [tabValue, setTabValue] = useState(0);

  return (
    <Box px={1}>
      <Box mb={2}>
        <TabsMenu
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabHeaders={[ 'Book Resource', 'Associated Orders' ]}
        />
      </Box>


      <Box
        sx={tabValue !== 0 ? {
          position: 'absolute',
          visibility: 'hidden',
          top: 0,
          width: 0,
          overflow: 'hidden',
        } : {}}
      >
        <TSResourceTab formData={formData} />
      </Box>
      <Box
        sx={tabValue !== 1 ? {
          position: 'absolute',
          visibility: 'hidden',
          top: 0,
          width: 0,
          overflow: 'hidden',
        } : {}}
      >
        <TSOrdersTab formData={formData} isUpdate={isUpdate} />
      </Box>
    </Box>
  );
}

export default TimeSheetForm;
