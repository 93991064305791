import React, { useEffect, useState } from 'react';

import InputFieldImport from './InputFieldImport';

import { IconButton, Box, Typography } from '@mui/material';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { base64ToImgSrc } from 'utils/fileHelperFunctions';

const AvatarImport = ({
  formData = {},
  name = '',
  dialogTitle = '',
  label = 'LOGO',
  size = '90px',
  ...rest
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const {
    watch,
    setValue
  } = formData;

  return <>
    <InputFieldImport
      customInput
      name={name}
      dialogTitle={dialogTitle}
      {...rest}
      dialogMaxWidth='xs'
      returnStringFile
      acceptedFileTypes={{'image': ['.jpg', '.jpeg', '.png']}}
      openDialog={openDialog}
      handleDialogClose={() => setOpenDialog(false)}
      setUploadedFile={fileString => {
        setValue(name, fileString);
        setOpenDialog(false);
      }}
    />

    <IconButton
      color='secondary'
      sx={{
        width: size,
        height: size,
        border: '1px solid grey',

        '&:hover': {
          '& svg': { display: 'block' },
          '& .MuiTypography-root': { display: 'none' },
          backgroundColor: 'rgba(0,0,0,.15)'
        },
        '& svg': { display: 'none' },

        backgroundImage: `url(${base64ToImgSrc(watch(name) || '')})`,
        backgroundSize: '100% 100%',
      }}

      onClick={() => {
        !watch(name) ? setOpenDialog(true) : setValue(name, '');
      }}
    >
      {!watch(name) ?
        <FileUploadRoundedIcon sx={{scale: '1.5', color: 'secondary.main'}}/> :
        <CloseRoundedIcon sx={{scale: '2', color: 'crimson'}}/>
      }

      {!watch(name) && <Typography variant='pb'>{label}</Typography>}
    </IconButton>
  </>;
}

export default AvatarImport;
