import React, { useState, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { omit } from "lodash";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SearchSelect from "common/input/SearchSelect";
import Selector from "common/input/Selector";
import InputField from "common/input/InputField";
import SwitchToggle from "common/input/SwitchToggle";

import { numberOnly } from "utils/textFormatUtils";
import useScreenSize from "hooks/useScreenSize";

import {
  fetchSaleOrderStatuses,
  fetchSaleOrderTypes,
} from "redux/slices/listSlice/listSlice";
import { useSelector, useDispatch } from "react-redux";

import CustomerProjectSelectors from "components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors";

const SalesOrderForm = ({
  formData,
  isEdit = false,
  handleEditApply = () => {},
  gridContainerProps = {},
}) => {
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useScreenSize();
  const [isBillingAddress, setIsBillingAddress] = useState(false);

  const { projects, saleOrderStatuses, saleOrderTypes } = useSelector(
    (state) => state.lists
  );

  useEffect(() => {
    !saleOrderStatuses?.length && dispatch(fetchSaleOrderStatuses());
    !saleOrderTypes?.length && dispatch(fetchSaleOrderTypes());
  }, [saleOrderStatuses, saleOrderTypes]);

  const watchedData = useWatch({ control: formData.control });

  useEffect(() => {
    setIsBillingAddress(watchedData["isBillingAddressDifferent"]);
  }, [watchedData]);

  //this not working
  const handleBillingChange = (value) => {
    if (value === false) {
      const newData = omit(watchedData, [
        "billing_AddressLine1",
        "billing_AddressLine2",
        "billing_Suburb",
        "billing_State",
        "billing_Postcode",
      ]);
      formData?.reset({
        ...newData,
        isBillingAddressDifferent: value,
      });
    }
  };

  const commonInputProps = {
    formData: formData,
    isEditable: isEdit,
    onEditApply: handleEditApply,
  };

  const gridItemSize = {
    md: 3,
    sm: 4,
    xs: 6,
  };

  return (
    <>
      <Grid
        px={!isMobile && 2}
        py={2}
        container
        rowSpacing={2}
        columnSpacing={2}
        {...gridContainerProps}
      >
        <CustomerProjectSelectors
          formData={formData}
          {...gridItemSize}
        />

        <Grid {...gridItemSize} item>
          <InputField required {...commonInputProps} name="orderRef" />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="thirdPartyOrderNo" />
        </Grid>

        <Grid {...gridItemSize} item>
          <SearchSelect
            required
            name="orderStatus"
            searchSelectData={saleOrderStatuses}
            returnLabel
            {...commonInputProps}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <SearchSelect
            required
            name="orderType"
            searchSelectData={saleOrderTypes}
            returnLabel
            {...commonInputProps}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="firstName" required />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="lastName" required />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="email" required />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name="mobileNumber"
            required
            format={numberOnly}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <Selector
            {...commonInputProps}
            name="salutation"
            required
            selectorData={[
              { value: "mr", label: "Mr" },
              { value: "miss", label: "Miss" },
              { value: "mrs", label: "Mrs" },
            ]}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name="salesOrderTotal"
            format={numberOnly}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name="netTotal"
            format={numberOnly}
          />
        </Grid>

        {/* <Grid {...gridItemSize} item>
          <SearchSelect
            required
            name="projectId"
            label="Project"
            searchSelectData={projects}
            {...commonInputProps}
          />
        </Grid> */}

        {/* <Grid {...gridItemSize} item>
          <DatePicker
            {...commonInputProps}
            name='salesOrderDate'
            required
          />
        </Grid> */}

        {/* {isTablet && <Grid {...gridItemSize} md={8} item></Grid>} */}

        <Grid md={4.5} sm={6} xs={12} item>
          <InputField {...commonInputProps} name="addressLine1" />
        </Grid>

        <Grid md={4.5} sm={6} xs={12} item>
          <InputField {...commonInputProps} name="addressLine2" />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="suburb" />
        </Grid>

        <Grid {...gridItemSize} item>
          <Selector
            {...commonInputProps}
            name="state"
            required
            selectorData={[
              { value: "NSW", label: "NSW" },
              { value: "VIC", label: "VIC" },
              { value: "WA", label: "WA" },
              { value: "TAS", label: "TAS" },
              { value: "SA", label: "SA" },
            ]}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="postcode" />
        </Grid>

        <Grid {...gridItemSize} md={6} item>
          <SwitchToggle
            {...commonInputProps}
            name="isBillingAddressDifferent"
            label={
              <Typography variant="p3">Is billing address different</Typography>
            }
            onChange={(name, val) => handleBillingChange(val)}
          />
        </Grid>

        {isBillingAddress && (
          <>
            <Grid md={4.5} sm={6} xs={12} item>
              <InputField {...commonInputProps} name="billing_AddressLine1" />
            </Grid>

            <Grid md={4.5} sm={6} xs={12} item>
              <InputField {...commonInputProps} name="billing_AddressLine2" />
            </Grid>

            <Grid {...gridItemSize} item>
              <InputField {...commonInputProps} name="billing_Suburb" />
            </Grid>

            <Grid {...gridItemSize} item>
              <Selector
                {...commonInputProps}
                name="billing_State"
                required
                selectorData={[
                  { value: "NSW", label: "NSW" },
                  { value: "VIC", label: "VIC" },
                  { value: "WA", label: "WA" },
                  { value: "TAS", label: "TAS" },
                  { value: "SA", label: "SA" },
                ]}
              />
            </Grid>

            <Grid {...gridItemSize} item>
              <InputField {...commonInputProps} name="billing_Postcode" />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default SalesOrderForm;
